import { usePunditUserContext } from "@circle-react/contexts";

export const useIsProfileFieldsEnabled = (): boolean => {
  const { currentCommunity, isLoading }: any = usePunditUserContext();

  return !!(
    !isLoading && currentCommunity?.profile_fields_feature_flag_enabled
  );
};

export const useIsHeadlineAsCustomProfileFieldEnabled = (): boolean => {
  const { currentCommunitySettings, isLoading }: any = usePunditUserContext();

  return !!(
    !isLoading &&
    currentCommunitySettings?.headline_as_custom_profile_field_enabled
  );
};

export function useShouldShowProfileFieldsUpgradeModal(): boolean {
  const { currentCommunitySettings, currentCommunityMember }: any =
    usePunditUserContext();

  const isUpgradeFlowsBetaFlagEnabled =
    currentCommunitySettings?.upgrade_flows_for_profile_fields_enabled;
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const isAdmin = currentCommunityMember?.is_admin;

  return Boolean(
    isUpgradeFlowsBetaFlagEnabled && !isProfileFieldsEnabled && isAdmin,
  );
}
