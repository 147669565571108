export const UPGRADE_PATHS = Object.freeze({
  INITIAL: "/",
  PLAN_DETAILS: "/plan_details",
  UPGRADE_SUCCESSFUL: "/upgrade_successful",
  BLOCKED_UPGRADE: "/blocked_upgrade",
  LOADING: "/loading",
  WORKFLOW_APP_SYNCING: "/workflow_app_syncing",
  ERROR: "/error",
  PRORATION: "/proration",
  TAX_ID_FORM: "/tax_id_form",
  CAN_DOWNGRADE: "/can_downgrade",
  MARKETING_HUB_SUBSCRIPTION_CHECK: "/marketing_hub_subscription_check",
});

export const UPGRADE_FLOW_SOURCES = Object.freeze({
  COMMUNITY_AI_SPLASH_SCREEN: "community_ai_splash_screen",
  ACTIVITY_LOGS: "activity_logs",
  ACTIVITY_SCORES_FILTER_CHIP: "activity_scores_filter_chip",
  ACTIVITY_SCORES_SCORE_CHIP: "activity_scores_score_chip",
  PROFILE_FIELDS_FILTER_CHIP: "profile_fields_filter_chip",
  PROFILE_FIELDS_SPLASH_SCREEN: "profile_fields_splash_screen",
  PROFILE_FIELDS_ANALYTICS_FILTER_CHIP: "profile_fields_analytics_filter_chip",
  COPILOT_UPGRADE_MODAL: "copilot_upgrade_modal",
  WORKFLOWS_UPGRADE_MODAL: "workflows_upgrade_modal",
  SPACE_LIMIT_UPGRADE_MODAL: "space_limit_upgrade_modal",
  ANALYTICS_FILTERS: "ANALYTICS_FILTERS",
  API_KEY_SPLASH_SCREEN: "api_key_splash_screen",
  API_KEY_CREATION_SCREEN: "api_key_creation_screen",
  CODE_SNIPPETS_SPLASH_SCREEN: "code_snipets_splash_screen",
  MODERATION_SETTINGS_SPLASH_SCREEN: "moderation_settings_splash_screen",
  INVITE_MEMBERS_MODAL: "invite_members_modal",
  ONBOARDING_WORKFLOWS_MODAL: "onboarding_workflows_modal",
  PAYWALLS_WORKFLOWS_MODAL: "paywalls_workflows_modal",
  SPACE_WORKFLOWS_MODAL: "space_workflows_modal",
  MARKETING_HUB_LANDING_PAGE: "marketing_hub_landing_page",
  COMMUNITY_VISIBILITY_SETTINGS: "community_visibility_settings",
  AI_AGENTS_ENABLE_MODAL: "ai_agents_enable_modal",
});
